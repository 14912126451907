<template>
  <v-footer
    class="app-footer mt-4"
    :style="applyPadding"
  >
    <p class="app-footer__text mr-4">
      Powered by <strong>iDtrust.</strong>
    </p>
    <a 
      href="https://www.idtrust.com.br/politicaprivacidade.html"
      target="_blank"
      class="app-footer__text"
    >
      Política de Privacidade
    </a>
  </v-footer>
</template>

<script>
export default {
  props: {
    noPaddingX: {
      type: Boolean,
      required: false
    },
    paddingLeft: {
      type: Boolean,
      required: false,
    },
    paddingRight:  {
      type: Boolean,
      required: false
    },
    navigationMini: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    applyPadding(){
      return {
        'padding-left' : this.applyPaddingLeft,
        'padding-right' :  this.paddingRight ? '452px' : '24px'
      }
    },
    applyPaddingLeft() {
      if(!this.noPaddingX || !this.paddingLeft) {
        return '24px'
      } else if(this.navigationMini){
        return '96px'
      } else {
        return '280px'
      }
    }
  }
}
</script>

<style scoped>
  .app-footer {
    background-color: inherit;
  }

  .app-footer__text {
    color: var(--custom-theme-black-054);
  }
</style>
